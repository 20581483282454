import React from "react";
import './App.css';

function Scroll() {
    return(
        <div className="scroll-cont">
            <h1 className="scror"></h1>
        </div>
    )
}

export default Scroll
import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import Navbartwo from "./navbartwo";

function Client() {
    return(
        <div className="client-page">
            <Navbartwo />
            <div className="clinet-cont">
                .
            </div>
            <h1 className="client-title">Here are some of our Industries.</h1>
            <div className="row">
                <div className="col-md-3 client-cc">
                    <img className="client-iccon" src="./images/pharma.jpg" alt="pharmacy" />
                    <h1 className="cline-name">Pharma Industries</h1>
                </div>
                <div className="col-md-3 client-cc">
                <img className="client-iccon" src="./images/heal.jpg" alt="heal" />
                    <h1 className="cline-name">Healthcare Industries</h1>
                </div>
                <div className="col-md-3 client-cc">
                <img className="client-iccon" src="./images/eng.jpg" alt="eng" />
                    <h1 className="cline-name">Engineering</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 client-cc">
                <img className="client-iccon" src="./images/bpo.jpg" alt="bpo" />
                    <h1 className="cline-name">IT & BPO</h1>
                </div>
                <div className="col-md-3 client-cc">
                <img className="client-iccon" src="./images/edu.jpg" alt="edu" />
                    <h1 className="cline-name">Educational Institutions</h1>
                </div>
                <div className="col-md-3 client-cc">
                <img className="client-iccon" src="./images/org.png" alt="org" />
                    <h1 className="cline-name">Govt Organizations</h1>
                </div>
            </div>
<hr/>
            <div className="row client-page-two">
                <div className="col-md-5">
                    <img className="client-img" src="./images/client.png" alt="image"/>
                </div>
                <div className="col-md-5 scroll-client">
                <div id="carouselExampleIndicators" class="carousel slide client-main-coro">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active cline-ic" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active client-coro">
                            <h1 className="client-scroll-txt">Our Client Says</h1>
                            <p className="client-txt">"Bell & Horn provides exceptional HR consulting services. They helped us streamline our HR policies and implemented a robust performance management system. Their expertise and professionalism are unmatched, and we highly recommend them."</p>
                            <p className="john">SAGAR</p>
                            <p className="pppp">HAPPY CLIENT</p>
                        </div>
                        <div class="carousel-item client-coro">
                            <h1 className="client-scroll-txt">Our Client Says</h1>
                            <p className="client-txt">"Bell & Horn business alliance services have been instrumental in forging valuable partnerships and expanding our network. Their expertise in creating strategic relationships has significantly enhanced our growth opportunities and collaborative success."</p>
                            <p className="john">VIKRAM</p>
                            <p className="pppp">HAPPY CLIENT</p>
                        </div>
                        <div class="carousel-item client-coro">
                            <h1 className="client-scroll-txt">Our Client Says</h1>
                            <p className="client-txt">Bell & Horn consulting and advisory services provided us with invaluable insights and strategic guidance. Their expertise in tackling complex business challenges and implementing effective solutions has been crucial for our success and growth.</p>
                            <p className="john">SURESH</p>
                            <p className="pppp">HAPPY CLIENT</p>
                        </div>
                        <div class="carousel-item client-coro">
                            <h1 className="client-scroll-txt">Our Client Says</h1>
                            <p className="client-txt">Bell & Horn corporate branding and communications services transformed our market presence. Their strategic approach to building a strong brand identity and managing communications has significantly enhanced our visibility and engagement.</p>
                            <p className="john">SWATI</p>
                            <p className="pppp">HAPPY CLIENT</p>
                        </div>
                    </div>
                    <br/>
                    <button class="carousel-control-prev butt" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next butt" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon butt" aria-hidden="true"></span>
                        <span class="visually-hidden butt">Next</span>
                    </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Client
import React from "react";
import './App.css';
import Navbar from "./navbar";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function Audit() {
    return(
        <div className="solutions-cont">
            <Navbartwo />
            <div className="hr-so">
                <h1 className="hrso-title">Auditing services</h1>
                <p className="hr-points"><span class="dot"></span>Accounts and finance  auditing</p>
                <p className="hr-points"><span class="dot"></span>HR audits</p>
                <p className="hr-points"><span class="dot"></span>Rawmeterial and inventory audits</p>
                <p className="hr-points"><span class="dot"></span>Vendor verifications</p>
                <p className="hr-points"><span class="dot"></span>Compliance audits</p>
            </div>
            <Footer />
        </div>
    )
}

export default Audit
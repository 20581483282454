import React from "react";
import './App.css';
import Navbar from "./navbar";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function Careers() {
    return(
        <div>
            <Navbartwo />
                <div className="careers-page">
                    <div className="row">
                        <div className="col-md-5">
                            <h2 className="careers-at">Careers at Bell And Horn</h2>
                            <h1 className="work-career">Current Openings</h1>
                            <p className="explore">Join Bell And Horn Consulting and be part of a dynamic team that drives innovation and transformation across industries. We offer a collaborative environment where your skills and passion can make a real impact. Explore exciting opportunities to grow your career with us.</p>
                            <button className="careers-btn">Will update soon..</button>
                        </div>
                        <div className="col-md-5">
                            <img className="careers-ima" src="./images/caree.jpg" alt="careers" />
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    )
}

export default Careers
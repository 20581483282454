import React from "react";
import './App.css';
import Navbar from "./navbar";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function Compliance() {
    return(
        <div className="solutions-cont">
            <Navbartwo />
            <div className="hr-so">
                <h1 className="hrso-title">Statutory Compliance</h1>
                <p className="hr-points"><span class="dot"></span>Licensing and renewal of Licenses for all kinds of businesses</p>
                <p className="hr-points"><span class="dot"></span>Compliance under Labor & Employment Laws</p>
                <p className="hr-points"><span class="dot"></span>Compliance under Industrial Laws</p>
                <p className="hr-points"><span class="dot"></span>Compliance under Company Laws & Corporate Laws</p>
                <p className="hr-points"><span class="dot"></span>Conducting Compliance Audit  </p>
                <p className="hr-points"><span class="dot"></span>Legal & advisory services under Industrial Dispute Act, 1948</p>
                <p className="hr-points"><span class="dot"></span>Compliance under Customs and Excise, Professional Tax and income tax & other laws</p>
            </div>
            <Footer />
        </div>
    )
}

export default Compliance
import { BrowserRouter, Route } from 'react-router-dom';
import { Routes, Switch } from 'react-router';
import About from './about';
import Footer from './footer';
import Home from './home';
import Navbar from './navbar';
import Scroll from './scroll';
import Side from './side';
import Contact from './contact';
import Services from './services';
import Client from './client';
import News from './news';
import Blogone from './blogone';
import Hrsolutions from './hrsolutions';
import Consulting from './consult';
import Business from './business';
import Oursourcing from './outsourcing';
import Survey from './survey';
import Orga from './orga';
import Corporate from './corporate';
import Compliance from './compliance';
import Audit from './audit';
import Careers from './careers';
import Blogtwo from './blogtwo';
import Blogthr from './blogthr';
import Blogfour from './blogfour';

function App() {
  return (
    <div className="App">
      <BrowserRouter> 
        <Routes>
        <Route exact path="/" element={<Home />} />
          <Route exact path="home/" element={<Home />} />
          <Route path="about/*" element={<About />} />
          <Route path="contact/*" element={<Contact />} />
          <Route path="services/*" element={<Services />} />
          <Route path="client/*" element={<Client />} />
          <Route path='careers/*' element={<Careers />} />
          <Route path='news/*' element={<News />} />
          <Route path='blogone/*' element={<Blogone />} />
          <Route path='blogtwo/*' element={<Blogtwo />} />
          <Route path='blogthr/*' element={<Blogthr />} />
          <Route path='blogfour/*' element={<Blogfour />} />
          <Route path='hrsolutions/*' element={<Hrsolutions />} />
          <Route path='consult/*' element={<Consulting />} />
          <Route path='business/*' element={<Business />} />
          <Route path='outsourcing/*' element={<Oursourcing />} />
          <Route path='survey/*' element={<Survey />} />
          <Route path='orga/*' element={<Orga />} />
          <Route path='corporate/*' element={<Corporate />} />
          <Route path='compliance/*' element={<Compliance />} />
          <Route path='audit/*' element={<Audit />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import Navbartwo from "./navbartwo";

function Services() {
    return(
        <div>
            <Navbartwo />
            <div className="services-cont">
                .
            </div>
            
            <div className="services-one-cont">
                <br/>
            <h1 className="sertvices-title">Our Services</h1>
                <div className="row">
                    <div className="col-md-3 services-one">
                        <img className="ser-top" src="./images/servvfour.png" alt="image"/>
                        <h1 className="Mobile">HR solutions</h1>
                        <p className="mob-txt">We provide end-to-end HR services to enhance recruitment, payroll, and employee management efficiency and support organizational goals. They often utilize technology for automation and data analysis.</p>
                        <a className="serv-link" href="./hrsolutions">MORE</a>
                    </div>
                    <div className="col-md-3 services-one">
                        <img className="ser-top" src="./images/servv.png" alt="image"/>
                        <h1 className="Mobile">Consulting and advisory services </h1>
                        <p className="mob-txt">Our consulting and advisory services offer expert insights and strategic guidance on various business challenges, helping you implement effective solutions and drive success.</p>
                        <a className="serv-link" href="./consult">MORE</a>
                    </div>
                    <div className="col-md-3 services-one">
                        <img className="ser-top" src="./images/servvfour.png" alt="image"/>
                        <h1 className="Mobile">Business Alliance </h1>
                        <p className="mob-txt">We facilitate strategic business alliances and partnerships to expand your network, drive collaborative growth, and create new opportunities for your organization.</p>
                        <a className="serv-link" href="./business">MORE</a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 services-one">
                        <img className="ser-top" src="./images/servvfour.png" alt="image"/>
                        <h1 className="Mobile">Outsourcing services</h1>
                        <p className="mob-txt">Our outsourcing services cover specialized functions such as IT, customer support, and administrative tasks, enabling you to focus on core business areas while we handle operational complexities.</p>
                        <a className="serv-link" href="./outsourcing">MORE</a>
                    </div>
                    <div className="col-md-3 services-one">
                        <img className="ser-top" src="./images/servfive.png" alt="image"/>
                        <h1 className="Mobile">Survey & Feedback reports</h1>
                        <p className="mob-txt">We conduct detailed surveys and feedback analyses to provide actionable insights, helping you improve organizational performance and enhance employee satisfaction.</p>
                        <a className="serv-link" href="./survey">MORE</a>
                    </div>
                    <div className="col-md-3 services-one">
                        <img className="ser-top" src="./images/servfive.png" alt="image"/>
                        <h1 className="Mobile">Organizational Effectiveness & Performance improvement</h1>
                        <p className="mob-txt">We enhance organizational performance through customized strategies and solutions, productivity, overall growth.</p>
                        <a className="serv-link" href="./orga">MORE</a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 services-one">
                        <img className="ser-top" src="./images/servvfour.png" alt="image"/>
                        <h1 className="Mobile">Corporate branding and communications </h1>
                        <p className="mob-txt">Our corporate branding and communications services build a strong brand identity and manage both internal and external communications to enhance your market presence.</p>
                        <a className="serv-link" href="./corporate">MORE</a>
                    </div>
                    <div className="col-md-3 services-one">
                        <img className="ser-top" src="./images/servv.png" alt="image"/>
                        <h1 className="Mobile">Statutory Compliance</h1>
                        <p className="mob-txt">We ensure your business meets all legal and regulatory requirements through meticulous compliance management, minimizing risks and maintaining operational integrity.</p>
                        <a className="serv-link" href="./compliance">MORE</a>
                    </div>
                    <div className="col-md-3 services-one">
                        <img className="ser-top" src="./images/servfive.png" alt="image"/>
                        <h1 className="Mobile">Auditing services</h1>
                        <p className="mob-txt">Our auditing services provide thorough evaluations of financial records and internal controls to ensure accuracy, regulatory compliance, and identify areas for improvement.</p>
                        <a className="serv-link" href="./audit">MORE</a>
                    </div>
                </div>
                <br/>
                <hr/>

                <div className="row">
                    <div className="col-md-5 opy-img">
                        <img className="img-team" src="./images/servteam.png" alt="image"/>
                    </div>
                    <div className="col-md-4 opy-columnn">
                        <h1 className="optmial">Optimal Choice</h1>
                        <p className="opt-txt">Sample text. Click to select the text box. Click again or double click to start coding the text. Eccep our sint occures opponent non provident.</p>
                        <br/>
                        <h1 className="optmial">Used Worldwide</h1>
                        <p className="opt-txt">Sample text. Click to select the text box. Click again or double click to start coding the text. Eccep our sint occures opponent non provident.</p>
                        <br/>
                        <h1 className="optmial">Instant Support</h1>
                        <p className="opt-txt">Sample text. Click to select the text box. Click again or double click to start coding the text. Eccep our sint occures opponent non provident.</p>

                    </div>
                </div>
                <hr/>

                <div className="team-cont">
                    <div className="row">
                        <div className="col-md-4">
                            <img className="team-imgg" src="./images/team.png" alt="image"/>
                        </div>
                        <div className="col-md-7 tea-info">
                            <h1 className="our-title-servo">Our Team</h1>
                            <p className="our-pass-servo">Our team at Bell And Horn is dedicated to providing top-tier manpower consulting services. With a diverse group of seasoned professionals, we specialize in identifying and nurturing talent to meet the unique needs of businesses. </p>
                            <a className="achor-servo" href="./about">More...</a>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
            <Footer />
        </div>
    )
}

export default Services
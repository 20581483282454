import React from "react";
import './App.css';
import Navbar from "./navbar";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function News() {
    return(
        <div className="news-cont">
            <Navbartwo />
            <div className="art-new">
                <h1 className="news-title">News and articles</h1>
                <p className="par-news">The Pulse of the Industry</p>
            </div>
            <div className="news-blogs">
                <p className="intro">Welcome to the Bell And Horn Media Hub – your go-to source for the latest press releases, industry insights, trending topics, and essential media resources. Stay updated with our recent news and connect with our media team.</p>
                <i class='fas fa-angle-double-down down-news'></i>
            </div>
            <div className="art-cont">
                <h1 className="artivle-title">Trending Articles from Our Newsroom.</h1>


                <div className="row news-article-one">
                    <div className="col-md-4 news-blog-cl-one">
                        <img className="news-blog-img" src="./images/90.webp" alt="article-one"/>
                    </div>
                    <div className="col-md-5 news-blog-cl-two">
                        <h1 className="news-blog-title">Tech Titan XTech Acquires Innovate AI</h1>
                        <p className="news-blog-info">In a significant move within the tech industry, XTech Corporation, a leading global provider of technology solutions, has announced its acquisition of Innovate AI.</p>
                        <a href="./blogone" className="news-blog-read-more">Read More...</a>
                    </div>
                </div>

                <div className="row news-article-one">
                    <div className="col-md-4 news-blog-cl-one">
                        <img className="news-blog-img" src="./images/90.webp" alt="article-one"/>
                    </div>
                    <div className="col-md-5 news-blog-cl-two">
                        <h1 className="news-blog-title">Tech Titan XTech Acquires Innovate AI</h1>
                        <p className="news-blog-info">In a significant move within the tech industry, XTech Corporation, a leading global provider of technology solutions, has announced its acquisition of Innovate AI.</p>
                        <a href="./blogtwo" className="news-blog-read-more">Read More...</a>
                    </div>
                </div>


                <div className="row news-article-one">
                    <div className="col-md-4 news-blog-cl-one">
                        <img className="news-blog-img" src="./images/90.webp" alt="article-one"/>
                    </div>
                    <div className="col-md-5 news-blog-cl-two">
                        <h1 className="news-blog-title">Tech Titan XTech Acquires Innovate AI</h1>
                        <p className="news-blog-info">In a significant move within the tech industry, XTech Corporation, a leading global provider of technology solutions, has announced its acquisition of Innovate AI.</p>
                        <a href="./blogthr" className="news-blog-read-more">Read More...</a>
                    </div>
                </div>


                <div className="row news-article-one">
                    <div className="col-md-4 news-blog-cl-one">
                        <img className="news-blog-img" src="./images/90.webp" alt="article-one"/>
                    </div>
                    <div className="col-md-5 news-blog-cl-two">
                        <h1 className="news-blog-title">Tech Titan XTech Acquires Innovate AI</h1>
                        <p className="news-blog-info">In a significant move within the tech industry, XTech Corporation, a leading global provider of technology solutions, has announced its acquisition of Innovate AI.</p>
                        <a href="./blogfour" className="news-blog-read-more">Read More...</a>
                    </div>
                </div>

                
            </div>
            <Footer />
        </div>
    )
}

export default News
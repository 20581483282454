import React from "react";
import './App.css';
import Navbar from "./navbar";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function Consulting() {
    return(
        <div className="solutions-cont">
            <Navbartwo />
            <div className="hr-so">
                <h1 className="hrso-title">Consulting and advisory services</h1>
                <p className="hr-points"><span class="dot"></span>Corporate strategy & governance</p>
                <p className="hr-points"><span class="dot"></span>Sustainability </p>
                <p className="hr-points"><span class="dot"></span>Business Strategies</p>
            </div>
            <Footer />
        </div>
    )
}

export default Consulting
import React from "react";
import './App.css';
import Navbar from "./navbar";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function Oursourcing() {
    return(
        <div className="solutions-cont">
            <Navbartwo />
            <div className="hr-so">
                <h1 className="hrso-title">Outsourcing services</h1>
                <p className="hr-points"><span class="dot"></span>Pay roll outsourcing</p>
                <p className="hr-points"><span class="dot"></span>Employee Retirement services</p>
                <p className="hr-points"><span class="dot"></span>Employee Benefits Administration</p>
                <p className="hr-points"><span class="dot"></span>Insurance claims</p>
                <p className="hr-points"><span class="dot"></span>Employee reimbursements </p>
                <p className="hr-points"><span class="dot"></span>Employee income tax returns and Form-16</p>
            </div>
            <Footer />
        </div>
    )
}

export default Oursourcing
import React from "react";
import './App.css';
import Navbar from "./navbar";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function Orga() {
    return(
        <div className="solutions-cont">
            <Navbartwo />
            <div className="hr-so">
                <h1 className="hrso-title">Organizational Effectiveness & Performance improvement</h1>
                <p className="hr-points"><span class="dot"></span>Set clear objectives aligned with the company’s vision to ensure unified efforts.</p>
                <p className="hr-points"><span class="dot"></span>Optimize processes and leverage technology for increased efficiency and productivity.</p>
                <p className="hr-points"><span class="dot"></span>Invest in employee engagement and development to enhance workforce performance.</p>
                <p className="hr-points"><span class="dot"></span>Use data-driven decision-making to adapt quickly and improve outcomes.</p>
                <p className="hr-points"><span class="dot"></span>Foster a culture of continuous improvement to maintain competitiveness and effectiveness.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Orga
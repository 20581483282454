import React from "react";
import './App.css';
import Navbar from "./navbar";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function Hrsolutions() {
    return(
        <div className="solutions-cont">
            <Navbartwo />
            <div className="hr-so">
                <h1 className="hrso-title">HR solutions</h1>
                <p className="hr-points"><span class="dot"></span>Recruitment services</p>
                <p className="hr-points"><span class="dot"></span>Staffing services</p>
                <p className="hr-points"><span class="dot"></span>Learning and development Services</p>
                <p className="hr-points"><span class="dot"></span>Designing and  developing Performance Management Systems </p>
                <p className="hr-points"><span class="dot"></span>Policy framework and implementation support</p>
                <p className="hr-points"><span class="dot"></span>Conducting Surveys and provide feedback reports</p>
                <p className="hr-points"><span class="dot"></span>Design and develop Employee compensation and reward system</p>
                <p className="hr-points"><span class="dot"></span>Human Resources Information Systems (HRIS)</p>
                <p className="hr-points"><span class="dot"></span>Conducting  HR Audits</p>
                <p className="hr-points"><span class="dot"></span>Employee background verification</p>
            </div>
            <Footer />
        </div>
    )
}

export default Hrsolutions
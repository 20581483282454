import React from "react";
import './App.css';

function Navbartwo() {
    return(
        <div>
            <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid nav-bar-bg">
                    <a class="navbar-brand" href="#"><img className="dawn-logo" src="./images/horn.jpg" /></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                        <a class="nav-link" aria-current="page" href="./home">Home</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./services">Services</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./client">Clients</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./careers">Careers</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="./about">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="./contact">Contact Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="./news">News and Articles</a>
                        </li>
                    </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbartwo
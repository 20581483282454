import React from "react";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function About () {
    return(
        <div>
            <Navbartwo />
            <div className="abt-ont-cont">
                <h1 className="chair">About Us</h1>
            </div>
            <p className="one-t-a">Bell And Horn Founded in 2014 by industry veteran Mr. Siva N B Reddy, Bell and Horn Consulting has quickly risen to prominence as a trusted management consulting and HR outsourcing firm. With over 16 years of experience across various sectors, including Pharma, Glass, Shipping, and BPO, Mr. Reddy has led the company to deliver exceptional value and sustainable solutions to organizations globally.</p>
            <p className="one-t-a">We focus on every aspect of the business ranging from Compliance to corporate strategy and provide consulting services in all business verticals and offer a full range of services to help our clients deal with critical business challenges. </p>
            <div className="abt-two-cont">
                <div className="row">
                    <div className="col-md-6 abt-col-one">
                    <h1 className="whom">What difference we make</h1>
                        <p className="our">Give Practical solutions.</p>
                        <p className="our">Provide Best insights</p>
                        <p className="our">Setting global benchmarks.</p>
                        <p className="our">Bringing transformational change.</p>
                        <p className="our">Creating sustainable advantage.</p>
                        <p className="our">Designing and aligning systems.</p>
                        <p className="our">Establishing Measurement tools and controls.</p>
                    </div>
                    <div className="col-md-6 abt-col-two">
                        <img className="abt-img-one" src="./images/abttwoo.png" alt="image"/>
                    </div>
                </div>

                <div className="about-read">
                    <h1 className="rea-dtitle">Our Focus</h1>
                    <p className="rea-ttext">We focus on serving for the organizations ranging from small enterprises, midsized business firms and world’s leading organizations across private, public, and social sectors   which includes nonprofit organizations and government agencies. </p>
                    <button className="leard-rea">Read more</button>
                </div>

                <div className="row">
                    <div className="col-md-6 abt-col-two">
                        <img className="abt-img-onee" src="./images/abttwoo.png" alt="image"/>
                    </div>
                    <div className="col-md-6 abt-col-one">
                        <h1 className="whom">Our Staff:</h1>
                        <p className="our">We engage full-time staff and consultants from exceptional backgrounds with advanced professional degrees and qualifications across all disciplines. Our team is composed of individuals with high leadership caliber, professional integrity, sharp analytical minds, and exceptional creativity. They bring a wealth of experience and innovative thinking to every assignment, ensuring that we deliver outstanding results tailored to meet the unique needs of our clients. Their diverse expertise and commitment to excellence empower us to tackle complex challenges and drive success in every project we undertake.</p>  
                    </div>
                </div>
            </div>
            <br/>
            <Footer />
        </div>
    )
}

export default About
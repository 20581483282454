import React from "react";
import './App.css';
import Navbar from "./navbar";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function Business() {
    return(
        <div className="solutions-cont">
            <Navbartwo />
            <div className="hr-so">
                <h1 className="hrso-title">Business Alliance</h1>
                <p className="hr-points"><span class="dot"></span>Mergers and acquisitions </p>
                <p className="hr-points"><span class="dot"></span>ransition services</p>

            </div>
            <Footer />
        </div>
    )
}

export default Business
import Footer from "./footer";
import Navbar from "./navbar";
import Navbartwo from "./navbartwo";

function Contact() {
    
    return(
        <div>
            <Navbartwo />
                <div className="cont-one-contact">
                    .
                </div>
                <h1 className="contac">Contact Us</h1>
                <p className="p-contact">Connect with us. We got you.</p>

                <div className="row contact-row">
                    <div className="col-md-6 contact-col">
                        <br/>
                        <h1 className="name">TELL US YOUR NAME:</h1>
                        <input className="first-name" type="text" id="fname" name="fname" placeholder="Name:" /> <br/>
                        <h1 className="name">ENTER YOUR EMAIL:</h1>
                        <input className="email-hol" type="email" id="email" placeholder="Eg: yyy@gmail.com" />
                        <h1 className="name">ENTER YOUR NUMBER:</h1>
                        <input className="phone" type="number" placeholder="Eg: 0000000000" />
                        <h1 className="name">MESSAGE:</h1>
                        <input className="msg" type="text" placeholder="Write us a message..." />
                        <br/>
                        <button className="button-contone">Send</button>
                    </div>
                    <div className="col-md-6 contact-coll">
                        <img className="contact-image" src="./images/mail.png" alt="image"/>
                    </div>
                </div>
            <Footer />
        </div>
    )
}
export default Contact
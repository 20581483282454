import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import Scroll from "./scroll";
import Side from "./side";
import Navbartwo from "./navbartwo";

function Home () {
    return(
        <div>
            <Navbartwo />
            <Scroll />
            <Side />
            <Footer />
        </div>
    )
}

export default Home
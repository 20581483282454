import React from "react";
import './App.css';
import Navbar from "./navbar";
import Footer from "./footer";
import Navbartwo from "./navbartwo";

function Corporate() {
    return(
        <div className="solutions-cont">
            <Navbartwo />
            <div className="hr-so">
                <h1 className="hrso-title">Corporate branding and communications</h1>
                <p className="hr-points"><span class="dot"></span>1.	Develop a strong, consistent brand identity that reflects the company’s values and mission.</p>
                <p className="hr-points"><span class="dot"></span>Craft clear and compelling messaging to effectively communicate with target audiences.</p>
                <p className="hr-points"><span class="dot"></span>Leverage multiple channels to maintain brand visibility and build trust with stakeholders.</p>
                <p className="hr-points"><span class="dot"></span>Engage in proactive reputation management to safeguard and enhance brand perception.</p>
                <p className="hr-points"><span class="dot"></span>Ensure internal communications align with external branding to create a unified corporate image.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Corporate
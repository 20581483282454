import React from "react";
import './App.css';

function Footer() {
    return(
        <div className="footer">
            <div className="row">
                <div className="col-md-3">
                    <h2 className="foot-main">BELL AND HORN CONSULTING</h2>

                </div>
                <div className="col-md-1 footer-second">
                    <a className="footer-services" href="./home">Home</a>
                    <br/>
                    <a className="footer-services" href="./services">Services</a>
                    <br/>
                    <a className="footer-services" href="./client">Clients</a>
                    <br/>
                    <a className="footer-services" href="./contact">Contact</a>
                    <br/>
                    <a className="footer-services" href="./about">About</a>
                </div>
                <div className="col-md-3">
                    <a className="footer-services" href="./news">News and Articles</a>
<br/>
<br/>
                    <p className="want">Want to read our news letters. Drop your email down.</p>
                    <form action="/action_page.php">
                        <input className="serach" type="text" placeholder="@gmail.com" name="search"/>
                        <button className="search-butt" type="submit">Submit</button>
                    </form>
                </div>
            <div className="col-md-2 footer-laaast">

                <iframe className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3803.025581431751!2d78.4047090749142!3d17.60152008332261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8f3014b5ebb3%3A0xc4d58f57f0447e1e!2sBell%20and%20horn%20consultancy%20dundigal!5e0!3m2!1sen!2sin!4v1724224947797!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <a className="coonnect">Mobile: +91 9866184481 </a>
                <a className="coonnect">Email: info@bellandhorn.com</a>
                <a className="coonnect">Email: bellandhornconsulting@gmail.com</a>
                <p className="copy">Copyright © 2024 Bell And Horn Company, Inc. All rights reserved.</p>
            </div>
            <a className="footer-prii">Privacy Policy</a>
            <a className="footer-priivv">Terms & Conditions</a>
            <a className="footer-priivv">Cookies Preference</a>
        </div>
    )
}

export default Footer
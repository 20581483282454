import React from "react";
import './App.css';

function Side() {
    return(
        <div>
            <div className="how">
                <h1 className="ho-tit">Who we are</h1>
                <h1 className="howtext">Bell and Horn Consulting is a management consulting and HR outsourcing firm focused on supporting the organizations across the globe to improve their business performance. Bell and Horn   partners with clients from the private, public, and social sectors across all industries to identify their opportunities and address their critical challenges, and transform their enterprises into the sustainable global organizations.</h1>
            </div>
            <div className="cont-two-cards">
            <h1 className="serv">Our Services</h1>
                <div className="row">
                    <div className="col-md-3">
                        <div class="card-one">
                            <img src="./images/sre.png" class="card-img-top" alt="image"/>
                            <div class="card-body">
                                <h5 class="card-title">HR solutions</h5>
                                <p class="card-text">We provide comprehensive HR solutions that streamline recruitment, payroll, and employee management.</p>
                                <a href="./services" class="btn btn-primary">For more..</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="card-one">
                            <img src="./images/sre.png" class="card-img-top" alt="image"/>
                            <div class="card-body">
                                <h5 class="card-title">Outsourcing services</h5>
                                <p class="card-text">Our outsourcing services handle specialized functions like IT, customer support, and administrative tasks.</p>
                                <a href="./services" class="btn btn-primary">For more..</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="card-one">
                            <img src="./images/sre.png" class="card-img-top" alt="image"/>
                            <div class="card-body">
                                <h5 class="card-title">Auditing services</h5>
                                <p class="card-text">Our auditing services offer thorough evaluations of financial records and internal controls to ensure accuracy.</p>
                                <a href="./services" class="btn btn-primary">For more..</a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="cont-two">
                <div className="row">
                    <div className="col-md-6">
                        <img className="cont-img" src="./images/man.png" alt="image"/>
                    </div>
                    <div className="col-md-6">
                        <h1 className="main-till">Our Vision</h1>
                        <p className="vis-home">To be the leading catalyst for sustainable business transformation, empowering organizations to achieve long-term success while creating a positive impact on the environment and society."</p>
                        <h1 className="pur-home">Our Purpose</h1>
                        <p className="vis-home">Our purpose is to guide organizations in aligning and integrating sustainable business practices into their core strategies, enabling them to thrive in a rapidly evolving world. In order to realize our purpose, we are committed to providing innovative solutions that balance economic growth with environmental stewardship and social responsibility, helping our clients build resilient and responsible enterprises for the future.</p>
                    </div>
                </div>
            </div>

            <div className="side-cont">
                <div className="row">
                    <div className="col-md-6 author">
                    <h1 className="main-two">Our Values</h1>
                        <p className="pbody"><span class="dot"></span>Professional Integrity</p>
                        <p className="pbody"><span class="dot"></span>Respect for profession and people</p>
                        <p className="pbody"><span class="dot"></span>Following local and  global regulations</p>
                        <p className="pbody"><span class="dot"></span>Maintaining world class quality standards</p>
                        <p className="pbody"><span class="dot"></span>Delivering value</p>
                        <p className="pbody"><span class="dot"></span>Creating positive impact on society. </p>
                    </div>
                    <div className="col-md-6">
                        <img className="si-de" src="./images/mmm.png" alt="image"/>
                    </div>
                </div>
            </div>

            <div className="cont-exp">
                <h1 className="main-two">Our business models and approaches</h1>
                <p className="pbody"><span class="dot"></span>Insight and Impact approach</p>
                <p className="pbody"><span class="dot"></span>Categorization and prioritization model</p>
                <p className="pbody"><span class="dot"></span>Design and Align model </p>
                <p className="pbody"><span class="dot"></span>Profitability and sustainability approach</p>
                <p className="pbody"><span class="dot"></span>Tools and Controls model</p>
                <p className="pbody"><span class="dot"></span>Integrity and Accountability model</p>
                <p className="pbody"><span class="dot"></span>Execution and Acceleration approach</p>
            </div>

            <div className="last-cont">
                <div className="row">
                    <div className="col-md-6 last-conts">
                        <p className="bnh"><strong>BELL AND HORN</strong> INSIGHTS</p>
                        <h1 className="max">Maximizing Value Potential</h1>
                        <p className="we">We meet often with CEOs to discuss AI—a topic that is both captivating and rapidly changing. After working with over 1,000 clients in the past year, we are sharing a new series of roadmaps designed to help CEOs and senior executives navigate their AI transformation journeys. With both predictive and generative AI at an inflection point, and even more exciting opportunities on the horizon, our focus for 2024 is turning AI’s potential into real profit.</p>
                        <a className="home-news" href="./news">For more Insights...</a>
                    </div>
                    <div className="col-md-4 last-conts">
                        <img className="norway" src="./images/nor.png" alt="image"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Side